import {IStyleParam, StyleParamType} from '@wix/tpa-settings';
import {CategoryListLayout} from '../constants';
import {gallery_categoryListItemFont, gallery_categoryListLayout, gallery_filterOptionsFont} from './baseStylesParams';

type IInternalStylesParamKeys = {
  [P in keyof InternalStylesParamKeys]: IStyleParam<InternalStylesParamKeys[P]>;
};

export type InternalStylesParamKeys = {
  internal_categoryListItemFont: StyleParamType.Font;
};

/**
 * For Style Params that are only used internally in this project and were never set from the editor settings.
 * These style params will never have values set, and therefore their values will always be the output of their `getDefaultValue()` function.
 *
 * Font and color style parameters are automatically converted into multiple CSS variables by the Yoshi infrastructure.
 * This solution addresses scenarios where the appropriate font or color style parameter needs to be conditionally
 * selected based on other style parameter values. To achieve this, a new internal style parameter is introduced to hold
 * the correct value, ensuring it is properly split into all required CSS variables by the Yoshi infrastructure.
 * This approach ensures that the Wix UI TPA components function correctly by having all the necessary CSS variables.
 */
export const internalStylesParams: IInternalStylesParamKeys = {
  internal_categoryListItemFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({getStyleParamValue, isMobile}) => {
      const verticalCategoryList =
        getStyleParamValue(gallery_categoryListLayout) === CategoryListLayout.Vertical && !isMobile;
      return getStyleParamValue(verticalCategoryList ? gallery_filterOptionsFont : gallery_categoryListItemFont);
    },
  },
};
