/* istanbul ignore file */
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor';
import {
  productItemCustomCssVars,
  productItemShouldRenderVars,
} from './common/components/ProductItem/ProductItem.cssVars';
import {carouselCustomCssVars} from './common/components/Carousel/Carousel.cssVars';
import {heroCustomCssVars, heroShouldRenderVars} from './category/components/Hero/Hero.cssVars';
import {BooleanStyleParamsKeys} from './styleParams/types';
import {arrowsNavigationWrapperCssVars} from './sliderGallery/components/SliderGallery/ArrowsNavigationWrapper/ArrowsNavigationWrapper.cssVars';
import {sliderGalleryCssVars} from './sliderGallery/components/SliderGallery/SliderGallery.cssVars';
import {
  categoryTreeCustomCssVars,
  categoryTreeShouldRenderVars,
} from './category/components/CategoryTree/CategoryTree.cssVars';
import {extendedGridGalleryShouldRenderVars} from './common/ExtendedGridGallery/ExtendedGridGallery.cssVars';

export type CustomCssVarsFnParams = Omit<Parameters<CustomCssVarsFn>[0], 'tpaData'>;

export type ShouldRenderVarsFnParams = Omit<Parameters<CustomCssVarsFn>[0], 'styleParams' | 'tpaData'> &
  Pick<Parameters<CustomCssVarsFn>[0]['styleParams'], 'booleans' | 'numbers'>;
export type ShouldRenderVarsKeys = keyof ReturnType<typeof getBaseShouldRenderValues> | BooleanStyleParamsKeys;

const getBaseCustomCssVars = ({styleParams: styles, isMobile, isRTL}: CustomCssVarsFnParams) => {
  return {
    filtersHeaderPaddingBottom: styles.booleans.gallery_showFiltersTitle ? '20px' : '0',
    ...heroCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...carouselCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...productItemCustomCssVars({styleParams: styles, isMobile, isRTL}),
    ...arrowsNavigationWrapperCssVars({styleParams: styles, isMobile, isRTL}),
    ...sliderGalleryCssVars({styleParams: styles, isMobile, isRTL}),
    ...categoryTreeCustomCssVars({styleParams: styles, isMobile, isRTL}),
  };
};

export const getBaseShouldRenderValues = ({booleans, numbers, isMobile, isRTL}: ShouldRenderVarsFnParams) => {
  return {
    showTitleWasTurnedOn: booleans.gallery_showTitle === true,
    showTitleWasNeverChanged: booleans.gallery_showTitle === undefined,
    ...heroShouldRenderVars({booleans, numbers, isMobile, isRTL}),
    ...productItemShouldRenderVars({booleans, numbers, isMobile, isRTL}),
    ...categoryTreeShouldRenderVars({booleans, numbers, isMobile, isRTL}),
    ...extendedGridGalleryShouldRenderVars({booleans, numbers, isMobile, isRTL}),
  };
};

const generateDisplayVars = (booleans) => {
  const result = {};
  for (const key of Object.keys(booleans)) {
    const newKey = `${key}-display`;
    const value = booleans[key];
    result[newKey] = value ? 'revert' : 'none';
  }
  return result;
};

export const baseCustomCssVars = (params: CustomCssVarsFnParams) => {
  const baseCssVars = getBaseCustomCssVars(params);
  const shouldRenderVars = getBaseShouldRenderValues({
    booleans: params.styleParams.booleans,
    numbers: params.styleParams.numbers,
    isMobile: params.isMobile,
    isRTL: params.isRTL,
  });

  const displayVars = generateDisplayVars({...params.styleParams.booleans, ...shouldRenderVars});
  return {...baseCssVars, ...displayVars};
};
